import { useNotification as useN } from "@kyvg/vue3-notification"
import type { WatchStopHandle } from "vue"

type PresetOptions = {
  type: string
  persistOnRouteChange: boolean
  persistOnFirstRouteChange: boolean
  duration: number
}

type ID = number | string

type Options = {
  id?: ID
  persistOnRouteChange?: boolean
  persistOnFirstRouteChange?: boolean
  duration?: number
}

const unwatchRouteHandlers: Record<ID, WatchStopHandle> = {}

export function useNotification() {
  const { notify } = useN()

  function handleRouteChange({
    id,
    persistOnRouteChange,
    persistOnFirstRouteChange,
  }: Pick<Options, "id" | "persistOnRouteChange" | "persistOnFirstRouteChange">) {
    if (!id) {
      return
    }

    unwatchRouteHandlers[id]?.()

    if (persistOnRouteChange) {
      return
    }

    const route = useRoute()
    let routeChangeCounter = 0
    const unwatch = watch(
      () => route.path,
      () => {
        routeChangeCounter++

        if (!persistOnFirstRouteChange || routeChangeCounter > 1) {
          notify.close(id)
          unwatch()
        }
      }
    )

    unwatchRouteHandlers[id] = unwatch
  }

  function createNotifyPreset(presetOptions: PresetOptions) {
    return async (
      text: string,
      {
        id = Date.now(),
        persistOnRouteChange = presetOptions.persistOnRouteChange,
        persistOnFirstRouteChange = presetOptions.persistOnFirstRouteChange,
        duration = presetOptions.duration,
      }: Options = {}
    ) => {
      handleRouteChange({ id, persistOnRouteChange, persistOnFirstRouteChange })

      notify.close(id)
      await new Promise((resolve) => setTimeout(resolve, 300)) // wait for close animation
      notify.close(id) // close immediately if it was opened while waiting

      notify({
        id: id as number,
        type: presetOptions.type,
        text: text,
        duration,
      })
    }
  }

  const notifySuccess = createNotifyPreset({ type: "success", persistOnRouteChange: true, persistOnFirstRouteChange: false, duration: 3000 })
  const notifyError = createNotifyPreset({ type: "error", persistOnRouteChange: false, persistOnFirstRouteChange: false, duration: -1 })

  async function hideNotification(id?: number | string) {
    notify.close(id)
  }

  return {
    notifySuccess,
    notifyError,
    hideNotification,
  }
}
