import { useStorage } from "@vueuse/core"

export const useTokensStore = defineStore("tokens", () => {
  const staffToken = useStorage<null | string>("st", null, localStorage)

  return {
    staffToken,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTokensStore, import.meta.hot))
}
