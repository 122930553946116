import validate from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/pages/runtime/validate.js";
import tokens_45global from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/packages/core/middleware/tokens.global.ts";
import auth_45global from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/middleware/auth.global.ts";
import manifest_45route_45rule from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.1_ioredi_h7bqfckjmz6lxtwwuvlf36zoci/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  tokens_45global,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}