<template>
  <v-floating-notifications />
  <div class="h-full">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
useHead({
  titleTemplate(title?: string) {
    const config = useRuntimeConfig()
    if (title) {
      return `${title} - appdb ${config.public.siteName}`
    }

    return `appdb ${config.public.siteName}`
  },
  meta: [
    {
      name: "viewport",
      content: "width=1250 initial-scale=0.1",
    },
  ],
})

onErrorCaptured((e) => {
  if (e instanceof APIError) {
    return false
  }
})
</script>
