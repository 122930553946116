<template>
  <div
    class="flex items-center gap-2.5 rounded-md border px-2.5 py-2 leading-5"
    :class="{
      'border-red-400 bg-red-100 dark:border-red-700 dark:bg-red-950': props.type === 'error',
      'border-yellow-400 bg-yellow-100 dark:border-yellow-700 dark:bg-yellow-950': props.type === 'warning',
      'border-green-400 bg-green-100 dark:border-green-700 dark:bg-green-950': props.type === 'success',
    }"
  >
    <div
      class="flex h-8 w-8 shrink-0 items-center justify-center rounded-lg text-2xl text-white dark:text-inherit"
      :class="{
        'bg-red-400 dark:bg-red-800': props.type === 'error',
        'bg-yellow-400 !text-stone-50 dark:bg-yellow-800': props.type === 'warning',
        'bg-green-400 dark:bg-green-800': props.type === 'success',
      }"
    >
      <Icon
        v-if="props.type === 'error' || props.type === 'warning'"
        name="tabler:alert-triangle-filled"
      />
      <Icon
        v-else-if="props.type === 'success'"
        name="tabler:square-rounded-check-filled"
      />
    </div>
    <div class="flex-grow whitespace-pre-line break-words">
      <slot />
    </div>
    <button
      v-if="closeable"
      class="ml-auto text-xl leading-none"
      @click="emit('close')"
    >
      <Icon name="tabler:x" />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    type?: "default" | "error" | "warning" | "success"
    closeable?: boolean
  }>(),
  {
    type: "default",
  }
)

const emit = defineEmits<{
  (e: "close"): void
}>()
</script>
