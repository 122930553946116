import axios from "axios"
import type { Method } from "axios"
import env from "core/env"
import type { RequestOptions, Response, ResponseErrorCode } from "core/types/api"

export class APIError extends Error {
  readonly response: Response

  constructor(response: Response) {
    const message = response.errors.map((error) => error.code).join(", ")
    super(message)
    this.response = response
  }

  hasCode(code: ResponseErrorCode) {
    return this.response.errors.some((error) => error.code === code)
  }
}

export class APIClient {
  baseURL: string

  constructor(baseURL: string) {
    this.baseURL = baseURL
  }

  static prepareURL(url: string) {
    if (url.endsWith("/")) {
      return url
    }

    return url + "/"
  }

  async request<T>(method: Method, url: string, options: RequestOptions = {}) {
    const { staffToken: token } = storeToRefs(useTokensStore())

    const client = axios.create({
      baseURL: this.baseURL,
      params: {
        st: token.value,
      },
    })

    const response = await client.request<Response<T>>({
      method,
      url: APIClient.prepareURL(url),
      ...options,
    })

    if (!options.silent && !response.data.success) {
      const { notifyError } = useNotification()
      for (const error of response.data.errors) {
        notifyError(error.translated, { id: url })
      }
    } else {
      const { hideNotification } = useNotification()
      hideNotification(url)
    }

    if (!response.data.success) {
      throw new APIError(response.data)
    }

    return response.data.data
  }

  get<T>(url: string, options: RequestOptions = {}) {
    return this.request<T>("GET", url, options)
  }

  post<T>(url: string, options: RequestOptions = {}) {
    return this.request<T>("POST", url, {
      ...options,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }
}

export const api = new APIClient(env.apiBaseURL)
