export default defineNuxtRouteMiddleware((to) => {
  if (to.query.st) {
    return navigateTo({
      replace: true,
      path: to.fullPath,
      query: {
        ...to.query,
        st: undefined,
      },
    })
  }
})
