import { default as indexFsq3fe25ZQMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/index.vue?macro=true";
import { default as _91id_931yG248ybN4Meta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/job/[id].vue?macro=true";
import { default as _91id_93UbTQ55q3EwMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/manage/[id].vue?macro=true";
import { default as _91id_93TNHtx3dDnyMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/upload/[id].vue?macro=true";
import { default as indexgnk4JupW4hMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/index.vue?macro=true";
import { default as _91id_93209YuBXdoCMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/manage/[id].vue?macro=true";
import { default as authu6c1BCsCFfMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/auth.vue?macro=true";
import { default as copyright_45claimHuJrGtqT0wMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/copyright-claim.vue?macro=true";
import { default as indexCoNWsYukBdMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/index.vue?macro=true";
import { default as _91id_93rkqqaIyVOOMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/manage/[id].vue?macro=true";
import { default as createBj5Os8MxG7Meta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/create.vue?macro=true";
import { default as indexmJeHFW6zAGMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/index.vue?macro=true";
import { default as indexNmVsSKlihOMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/payment-providers/index.vue?macro=true";
import { default as stripeFpV940sihPMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/payment-providers/stripe.vue?macro=true";
import { default as indexUYGx59N0bMMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/products/index.vue?macro=true";
import { default as _91id_93yACNdtsrfQMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/products/manage/[id].vue?macro=true";
import { default as indexiSJymRpHGCMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/index.vue?macro=true";
import { default as createTpOetrQms3Meta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/create.vue?macro=true";
import { default as indexd1kzH3HU17Meta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/index.vue?macro=true";
import { default as featuresONZxcPn0wBMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/features.vue?macro=true";
import { default as managegKsZhSWKNcMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/info/manage.vue?macro=true";
import { default as overview22pKpM7PVZMeta } from "C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/overview.vue?macro=true";
export default [
  {
    name: "apps-binary-packages",
    path: "/apps/binary-packages",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/index.vue")
  },
  {
    name: "apps-binary-packages-job-id",
    path: "/apps/binary-packages/job/:id()",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/job/[id].vue")
  },
  {
    name: "apps-binary-packages-manage-id",
    path: "/apps/binary-packages/manage/:id()",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/manage/[id].vue")
  },
  {
    name: "apps-binary-packages-upload-id",
    path: "/apps/binary-packages/upload/:id()",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/binary-packages/upload/[id].vue")
  },
  {
    name: "apps-identifiers",
    path: "/apps/identifiers",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/index.vue")
  },
  {
    name: "apps-identifiers-manage-id",
    path: "/apps/identifiers/manage/:id()",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/apps/identifiers/manage/[id].vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: authu6c1BCsCFfMeta || {},
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/auth.vue")
  },
  {
    name: "copyright-claim",
    path: "/copyright-claim",
    meta: copyright_45claimHuJrGtqT0wMeta || {},
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/copyright-claim.vue")
  },
  {
    name: "developer-test-devices",
    path: "/developer/test-devices",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/index.vue")
  },
  {
    name: "developer-test-devices-manage-id",
    path: "/developer/test-devices/manage/:id()",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/test-devices/manage/[id].vue")
  },
  {
    name: "developer-tokens-create",
    path: "/developer/tokens/create",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/create.vue")
  },
  {
    name: "developer-tokens",
    path: "/developer/tokens",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/developer/tokens/index.vue")
  },
  {
    name: "finances-payment-providers",
    path: "/finances/payment-providers",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/payment-providers/index.vue")
  },
  {
    name: "finances-payment-providers-stripe",
    path: "/finances/payment-providers/stripe",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/payment-providers/stripe.vue")
  },
  {
    name: "finances-products",
    path: "/finances/products",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/products/index.vue")
  },
  {
    name: "finances-products-manage-id",
    path: "/finances/products/manage/:id()",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/finances/products/manage/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/index.vue")
  },
  {
    name: "legal-copyright-infringements-create",
    path: "/legal/copyright-infringements/create",
    meta: createTpOetrQms3Meta || {},
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/create.vue")
  },
  {
    name: "legal-copyright-infringements",
    path: "/legal/copyright-infringements",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/copyright-infringements/index.vue")
  },
  {
    name: "legal-features",
    path: "/legal/features",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/features.vue")
  },
  {
    name: "legal-info-manage",
    path: "/legal/info/manage",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/info/manage.vue")
  },
  {
    name: "legal-overview",
    path: "/legal/overview",
    component: () => import("C:/Users/zond8/Documents/WORK_SITES/appdb.cc/publisher/appdb-publisher/apps/area/pages/legal/overview.vue")
  }
]