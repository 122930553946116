import type { Agreement } from "core/types/api"

export const useAgreementsStore = defineStore("agreements", () => {
  const user = useUserStore()
  const allAgreements = ref<Agreement[]>([])
  const unsignedAgreements = computed(() => allAgreements.value.filter((agreement) => !agreement.signed_at))

  const isLoading = ref(false)
  async function load() {
    if (!user.isAuthorized) {
      allAgreements.value = []
      return []
    }

    isLoading.value = true
    try {
      allAgreements.value = await getAgreements()
    } catch (e) {
      allAgreements.value = []
      throw e
    } finally {
      isLoading.value = false
    }
  }

  watch(() => [user.isAuthorized, user.data?.enabled_features_identifiers], load, { deep: true })

  return {
    allAgreements,
    unsignedAgreements,
    load,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAgreementsStore, import.meta.hot))
}
