import type {
  App,
  InAppProduct,
  InAppProductType,
  LicenseType,
  OfficialIPA,
  OfficialIPAAnalyzeJob,
  OfficialIPAScreenshotOS,
  PublisherAccount,
  StripeConfiguration,
} from "core/types/api"
import type { RequestOptions } from "core/types/api"

export function getAccountData({ options }: { options?: RequestOptions } = {}) {
  return api.get<PublisherAccount>("get_account_data", options)
}

export function editApp(data: {
  id?: string
  tos_text: string
  privacy_policy_text: string
  license_text: string
  use_default_tos: 0 | 1
  use_default_privacy_policy: 0 | 1
  use_default_license: 0 | 1
  website_uri: string
  support_uri: string
  identifier?: string
  label: string
  lead: string
  license_type: LicenseType
  genre_name: string
  is_18plus: 0 | 1
  do_not_sign: 0 | 1
  publish_at?: number
  associated_uris: string[]
}) {
  return api.post<App>("edit_app", {
    data: {
      ...data,
      associated_uris: undefined,
      associated_uris_json: JSON.stringify(data.associated_uris.filter(Boolean)),
    },
  })
}

export function getApps(params?: { identifier?: string }) {
  return api.get<App[]>("get_apps", { params })
}

export function addOfficialIPA(data: { scope: string; job_id: string; ipa: File }, options: RequestOptions = {}) {
  return api.post("add_official_ipa", { data, ...options })
}

export function deleteOfficialIpa(data: { id: number }) {
  return api.post("delete_official_ipa", { data })
}

export function getOfficialIPAs(params: { id?: number; scope?: string } = {}) {
  return api.get<OfficialIPA[]>("get_official_ipas", {
    params,
  })
}

export function getOfficialIPAAnalyzeJobs(params: { ids?: string[] } = {}) {
  return api.get<OfficialIPAAnalyzeJob[]>("get_official_ipa_analyze_jobs", { params })
}

export function editOfficialIPAMetadata(data: {
  id: number
  min_ios_version: string
  min_ipados_version: string
  min_tvos_version: string
  min_macos_version: string
  min_watchos_version: string
  min_visionos_version: string
  description: string
  whatsnew: string
  screenshots_uris_by_os_type: Record<OfficialIPAScreenshotOS, string[]>
}) {
  return api.post("edit_official_ipa_metadata", {
    data: {
      ...data,
      screenshots_uris_by_os_type: JSON.stringify(data.screenshots_uris_by_os_type),
    },
  })
}

export function assignIPAToApp(data: { to_app_identifier: string; ipa_id: number }) {
  return api.post("assign_ipa_to_app", { data })
}

export function enableAccountFeature(data: { identifier: string }) {
  return api.post("enable_account_feature", { data })
}

export function getProducts(params: { app_identifier?: string; product_identifier?: string } = {}) {
  return api.get<InAppProduct[]>("get_products", { params })
}

export function editProduct(data: {
  is_enabled?: 0 | 1
  tos_text?: string
  use_default_tos?: 0 | 1
  identifier?: string
  app_identifier: string
  amount?: number
  name_json: string
  description_json: string
  price_cents_eur?: number
  icon_uri: string
  license_type: LicenseType
  type?: InAppProductType
}) {
  return api.post<InAppProduct>("edit_product", { data })
}

export function deleteProduct(data: { app_identifier: string; identifier: string }) {
  return api.post("delete_product", { data })
}

export function uploadFile(data: { type: "product_icon"; file: File }) {
  return api.post<string>("upload_file", { data })
}

export function getStripeConfiguration() {
  return api.get<null | StripeConfiguration>("get_stripe_configuration")
}

export function editStripeConfiguration(data: { stripe_sk: string; stripe_pk: string }) {
  return api.post("edit_stripe_configuration", { data })
}
