import type { RouteLocationRaw } from "vue-router"
import type { RequiredRole, RequiredRoleName } from "~/types/app"

function getRequiredRoleName(requiredRole?: RequiredRole): RequiredRoleName {
  if (!requiredRole) {
    return "user"
  }

  if (typeof requiredRole === "string") {
    return requiredRole
  }

  return requiredRole.name
}

function getRedirectLocation(requiredRoleName: RequiredRoleName, requiredRole?: RequiredRole): undefined | RouteLocationRaw {
  if (typeof requiredRole === "object" && requiredRole.redirect) {
    return requiredRole.redirect
  }

  if (requiredRoleName === "guest") {
    return "/"
  }

  if (requiredRoleName === "user") {
    return "/auth"
  }
}

export default defineNuxtRouteMiddleware((to) => {
  const user = useUserStore()
  const requiredRoleName = getRequiredRoleName(to.meta.requiredRole)

  if (user.role === requiredRoleName || requiredRoleName === "any") {
    return
  }

  const redirectLocation = getRedirectLocation(requiredRoleName, to.meta.requiredRole)
  if (redirectLocation) {
    return navigateTo(redirectLocation)
  }
})
