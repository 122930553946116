import * as Sentry from "@sentry/vue"
import type { PublisherAccount } from "core/types/api"
import type { Role } from "~/types/app"

export const useUserStore = defineStore("user", () => {
  const { staffToken: token } = storeToRefs(useTokensStore())

  const data = ref<null | PublisherAccount>(null)
  watchEffect(() => {
    if (data.value) {
      Sentry.setUser({ id: data.value.id })
      return
    }

    Sentry.setUser(null)
  })

  const isAuthorized = computed(() => Boolean(data.value))
  const role = computed<Role>(() => (isAuthorized.value ? "user" : "guest"))

  async function getData() {
    if (!token.value) {
      data.value = null
      return
    }

    try {
      data.value = await getAccountData({ options: { silent: true } })
      return data.value
    } catch (e) {
      if (e instanceof APIError && (e.hasCode("ERROR_INVALID_STAFF_TOKEN") || e.hasCode("ERROR_NO_SUCH_ACCOUNT"))) {
        logout()
      }

      throw e
    }
  }

  async function login(newToken: null | string) {
    token.value = newToken
    return await getData()
  }

  function logout() {
    data.value = null
    token.value = null
    navigateTo("/auth")
  }

  return {
    token: createReadonlyStoreField(token),
    isAuthorized,
    role,
    data: createDeepReadonlyStoreField(data),
    getData,
    login,
    logout,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
